import React from 'react'
import styled from 'styled-components'

import SEO from '@/components/seo'
import CalculatorStructure from '@/components/CalculatorStructure'
import { Test } from '@/components/Calculator/Grade'

import { CALCULATORS } from '@/helpers/mocks/calculators'
import SectionInfoCard from './SectionInfoCard'

const StyledTestCalculator = styled(Test)`
  min-width: unset;
  max-width: unset;
`

const TestCalculator = () => (
  <>
    <SEO title="Test" />
    <CalculatorStructure
      section="grade"
      headerTitle="grade"
      title={CALCULATORS.grade.test.title}
      description={CALCULATORS.grade.test.description}
      calculatorNode={<StyledTestCalculator />}
      asideCards={<SectionInfoCard />}
    />
  </>
)

export default TestCalculator
